@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
  line-height: 1;
  -webkit-tap-highlight-color: transparent;
  color: #000;
}

html,
body {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.app {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
  padding: 15px;
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text-description-container {
  padding-top: 25px;
  padding-left: 50px;
  padding-bottom: 0px;
  .text-container {
    display: flex;
    justify-content: space-between;
  }
  .text {
    color: #393939;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 10px;
    b {
      font-weight: 700;
    }
  }
}
