.impactView {
  display: flex;
  flex-direction: column;
  margin-top: 11px;
  margin-left: 50px;
  min-height: 300px;
  width: 100%;
  &.ivPositionImpact {
    .ivParam {
      max-width: 95px;
      min-width: 95px;
    }
  }
  &.ivRoleImpact {
    .ivParam {
      max-width: 95px;
      min-width: 95px;
    }
  }
  .ivRow {
    display: flex;
    justify-content: flex-start;

    width: auto;
    margin-bottom: 15px;
  }
  .ivTitleView {
    width: 260px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
  }
  .ivActView {
    width: auto;
    margin-right: 70px;
    display: flex;
    flex-direction: column;
  }
  .ivBlockView {
    display: flex;
    width: auto;
    flex-direction: column;
  }
  .ivMainTitle {
    border: 0.67px solid #397890;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #397890;
    text-align: center;
    font-size: 18.763px;
    font-weight: 600;
    padding: 6.701px 26.804px;
    width: 100%;
    margin-bottom: 11px;
  }
  .ivParams {
    display: flex;
  }
  .ivParam {
    display: flex;
    flex-direction: column;
    margin-right: 9px;
    width: 100%;
    max-width: 75px;
    min-width: 75px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .ivParamTitle {
    padding: 0 10px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background: #e1e6ed;
    color: #545454;
    text-align: center;
    font-size: 10px;
    height: 26px;
    font-weight: 600;
    margin-bottom: 5px;
  }
  .ivParamDescription {
    padding: 0 10px;
    display: flex;
    width: 100%;
    height: 26px;
    justify-content: center;
    align-items: center;
    background: #f2f2f2;
    color: #545454;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    display: none;
  }
  .ivTitle {
    border-radius: 16.082px;
    border: 0.67px solid rgba(17, 30, 44, 0.1);
    display: flex;
    align-items: center;
    height: 63px;
    width: 100%;
    color: #397890;
    font-size: 13.543px;
    font-weight: 600;
    padding: 0 14px;
    img {
      width: 37px;
      margin-right: 14px;
    }
  }
  .ivParamArea {
    height: 63px;
    width: 100%;
    position: relative;
  }
  .ivParamDeclared,
  .ivParamFirm {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  .ivParamFirm {
    z-index: 2;
    align-items: flex-end;
  }
  .ivParamDeclaredPositive {
    border: 1px solid #d3f2dd;
  }
  .ivParamFirmPositive {
    background-color: #a7e5b0;
  }
  .ivParamDeclaredNegative {
    border: 1px solid #ffe3e0;
  }
  .ivParamFirmNegative {
    background-color: #fc9ca2;
  }
  .ivParamFirmPercentage {
    color: #000;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    width: 100%;
    margin-bottom: 5px;
  }
  .ivParamDeclaredPercentage {
    color: #000;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    width: 100%;
    margin-top: -15px;
  }
  .ivParamDeclaredPercentageBottom {
    margin-top: -13px;
  }
  .ivParamDeclaredPercentageHide {
    display: none;
  }
  .ivParamFirmReset {
    height: 100% !important;
    background-color: #f2f2f2;
    .ivParamFirmPercentage {
      font-weight: 400;
      margin-bottom: 5px;
    }
  }
  .ivDescriptionView {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
  .ivDescriptionItem {
    display: flex;
    align-items: center;
    margin-right: 24px;
    &:last-of-type {
      margin-right: 0;
    }
  }
  .ivDescriptionBox {
    display: flex;
    align-items: center;
    color: #393939;
    margin-right: 7px;
  }
  .ivDescriptionBoxItem {
    width: 13px;
    height: 13px;
  }
  .ivDescriptionTitle {
    color: #393939;
    font-size: 11px;
    font-weight: 400;
    line-height: 0;
    height: 13px;
    display: flex;
    align-items: center;
    strong {
      height: 13px;
      line-height: 0;
      display: flex;
      align-items: center;
      margin-right: 3px;
      font-weight: 700;
    }
  }
  .ivDescriptionBoxItemText {
    font-size: 11px;
    font-weight: 400;
    height: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 13px;
    line-height: 0;
  }
}
