.general-results-container {
  display: flex;
  flex-direction: column;

  .general-results-chart {
    width: 1000px;
    margin-left: 80px;
    height: 450px;
    overflow: hidden;
    position: relative;
    padding-top: 35px;
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 1300px) {
      margin-left: 68px;
    }
    @media (max-width: 1200px) {
      margin-left: -50px;
    }
    .general-results-img {
      position: absolute;
    }
    .apexcharts-tooltip {
      //display: none !important;
      // top: 50% !important;
      // left: 50% !important;
    }
    .apexcharts-legend-series,
    .apexcharts-series-markers {
      pointer-events: none;
    }
    .apexcharts-yaxis {
      .apexcharts-text {
        display: none;
      }
    }

    .apexcharts-legend-series {
      display: flex;
      align-items: center;
      margin-left: 15px !important;
    }

    .apexcharts-legend-text {
      padding-left: 20px;
    }

    .apexcharts-legend {
      top: 35px !important;
      right: 170px !important;
      border-radius: 6.283px;
      border: 1.885px solid rgba(17, 30, 44, 0.1);
      background: rgba(255, 255, 255, 0.8);
      width: 110px;
      height: 79px;
      display: flex;
      padding-top: 7px;
      visibility: hidden;
    }

    .apexcharts-datalabel {
      font-weight: 700;
    }
  }
}
