.header {
  width: 100%;
  max-width: 1289px;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.8);
  height: 94px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  margin: 0 auto;
}

.titleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #041e42;
  text-align: center;
  font-size: 15.241px;
  font-weight: 600;
  padding-left: 55px;
}
