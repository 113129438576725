.groupComparisonContainer {
  margin: 90px auto 170px;
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #0061ba;
  text-align: center;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 30px;
}

.link {
  display: flex;
  min-width: 100%;
  height: 49x;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #0061ba;
  background: #fff;
  color: #0061ba;
  font-size: 20px;
  font-weight: 600;
  transition: all 0.3s linear;
  margin-bottom: 18px;
  &:hover {
    background: #0061ba;
    color: #fff;
  }
}
