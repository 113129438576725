.infoBox {
  width: 160.032px;
  height: 169.038px;
  border-radius: 6.928px;
  border: 0.693px solid rgba(17, 30, 44, 0.1);
  background: rgba(255, 255, 255, 0.8);
  padding: 15px 15px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.infoBox_img1 {
  height: 63px;
  width: 63px;
  margin-bottom: 15px;
}

.infoBox_img2 {
  height: 28px;
  margin-bottom: 10px;
}

.infoBox_text {
  color: #393939;
  font-size: 11.084px;
  font-weight: 400;
}
