.menu {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  left: 65px;
  top: 33px;
}

.aditionalMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  right: 30px;
  top: 11px;
}

.menuItem {
  display: flex;
  min-width: 163px;
  height: 38px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid #0061ba;
  background: #fff;
  color: #0061ba;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s linear;
  margin-bottom: 6px;
  &:hover {
    background: #0061ba;
    color: #fff;
  }
}

.menuActiveItem {
  background: #0061ba;
  color: #fff;
}

.menuItemBack {
  min-width: 84px;
  max-width: 84px;
  border-color: transparent;
  &:hover {
    background: #314d73;
  }
  img {
    rotate: 180deg;
    margin-right: 5px;
  }
}
