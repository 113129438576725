.appContainer {
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.9);
  margin: 15px auto 15px;
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-width: 1289px;
  position: relative;
}
