.detailed-results-container {
  width: 100%;
  max-width: 870px;
  margin: 0;
  padding-top: 90px;
  &.explanation-main-container {
    max-width: 945px;
  }
  .wrapper {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    &.main {
      margin-top: 0px;
      flex-direction: row;
      align-items: center;
      margin-bottom: 0px;
    }
  }

  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    position: relative;
    .side {
      width: 63px;
    }
    .center {
      width: 345px;
      margin: 0 15px;
    }

    .side-title {
      height: 38px;
      background-color: #565656;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      font-size: 19px;
      font-weight: 700;
    }

    .side-description {
      display: flex;
      border-left: 2px solid #313f55;
      border-right: 2px solid #313f55;

      .side-item {
        height: 38px;
        font-size: 19px;
        font-weight: 700;
        color: #313f55;
        padding-left: 18px;
        padding-right: 18px;
        display: flex;
        align-items: center;
        &.item-border {
          border-left: 1px solid #313f55;
          border-right: 1px solid #313f55;
        }
      }
    }

    .top {
      height: 33px;
      border-radius: 5px;
      background: #314d73;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: 700;
    }

    .bottom-side {
      height: 53px;
      border-radius: 5px;
      background: #565656;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      span {
        font-weight: 400;
        margin-top: 4px;
        color: #fff;
      }
    }
    .bottom-center {
      border-radius: 5px;
      background: #f5f5f5;
      height: 53px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 30px;
    }

    .item-wrapper {
      display: flex;
      align-items: center;

      .box {
        width: 31px;
        height: 31px;
        border-radius: 3px;
        border: 0.5px solid #143e65;
        margin-right: 10px;
        &.center {
          margin-right: 0;
        }
      }
      .title {
        font-weight: 700;
        color: #313f55;
        font-size: 12px;
        &.center {
          font-size: 12px;
          width: 61px;
          font-weight: 400;
        }
      }
    }

    .anchor {
      position: absolute;
      width: 38px;
      height: 38px;
      right: -53px;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      background: #0061ba;
      cursor: pointer;
      transition: 0.3s all linear;
      &:hover {
        background: #314d73;
      }
    }
  }

  .title-container {
    margin-right: 80px;
    height: 38px;
    border: 1px solid #0061ba;
    background: rgba(249, 249, 249, 0.6);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #121212;
    font-size: 12px;
    font-weight: 400;
    font-size: 19px;
    width: 100%;
    max-width: 361px;
    margin-bottom: 8px;
    padding-left: 10px;
    @media (max-width: 1200px) {
      margin-left: 100px;
      margin-right: 50px;
      font-size: 18px;
    }
  }

  .green {
    background-color: #a7e5b0;
  }
  .gray {
    background-color: #e1e1e1;
  }
  .red {
    background-color: #fc9ca2;
  }

  .logo-container {
    border-radius: 12px;
    border: 0.75px solid rgba(17, 30, 44, 0.1);
    font-weight: 600;
    color: #397890;
    font-size: 14px;
    text-align: left;
    justify-content: flex-start;
    padding-left: 10px;
    max-width: 268px;
    margin-right: 50px;
    margin-left: 50px;
    background-color: #fff;
    img {
      margin-right: 15px;
      max-height: 35px;
      max-width: 35px;
    }
  }
  .explanation-container {
    max-width: 378px;
    margin-right: 15px;
    margin-left: 50px;
    font-size: 12px;
    line-height: normal;
  }

  .title-container-first {
    background: #397890;
    color: #fff;
    font-size: 15.157px;
    font-weight: 600;
  }
  .brand {
    position: absolute;
    border-radius: 12px;
    border: 1px solid rgba(17, 30, 44, 0.1);
    width: 134.25px;
    height: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 65px;
    img {
      max-height: 95%;
    }
  }
}
